import 'flickity/css/flickity.css';

import debounce from 'lodash-es/debounce';
import Flickity from 'flickity';
import DOMPurify from 'dompurify';

import './carousel.component.scss';

interface CarouselItem {
  text: string;
  icon: string;
}

type CarouselDirections = 'previous' | 'next';

export class CarouselComponent {
  private static readonly CAROUSEL_SELECTOR = '.js-carousel';
  private static readonly CAROUSEL_ITEM_SELECTOR = '.js-carousel-item';
  private static readonly CAROUSEL_CONTROL_SELECTOR = '.js-carousel-control';
  private static readonly BASE_ITEM_CLASS = 'carousel__list-item';
  private static readonly CAROUSEL_CONFIG = {
    cellSelector: CarouselComponent.CAROUSEL_ITEM_SELECTOR,
    contain: true,
    cellAlign: 'left',
    prevNextButtons: false
  };
  private static readonly CAROUSEL_BREAKPOINTS = [
    { breakpoint: 768, cells: 1 },
    { breakpoint: 1100, cells: 2 },
    { breakpoint: 10000, cells: 3 }
  ];

  private carousel: any;
  private carouselList: HTMLDivElement;
  private carouselControls: Record<CarouselDirections, HTMLButtonElement | null> = {
    previous: null,
    next: null
  };

  static init() {
    return new CarouselComponent();
  }

  private static createItem(item: CarouselItem): HTMLDivElement {
    const { BASE_ITEM_CLASS, CAROUSEL_ITEM_SELECTOR } = CarouselComponent;
    const elem = document.createElement('div');
    elem.classList.add(BASE_ITEM_CLASS);
    elem.classList.add(CAROUSEL_ITEM_SELECTOR.slice(1));
    elem.innerHTML = `
      <span class="${BASE_ITEM_CLASS}-icon">${DOMPurify.sanitize(item.icon)}</span>
      <span class="${BASE_ITEM_CLASS}-text">${DOMPurify.sanitize(item.text)}</span>
    `;

    return elem;
  }

  private constructor() {
    this.carouselList = document.querySelector<HTMLDivElement>(CarouselComponent.CAROUSEL_SELECTOR)!;
    this.fetchItems().then(this.initItems);
    window.addEventListener('resize', this.onResize);
  }

  private onResize = debounce(() => this.setCarouselCells(), 500);

  private initItems = (items: CarouselItem[]): void => {
    const { CAROUSEL_SELECTOR, CAROUSEL_CONFIG, createItem } = CarouselComponent;
    items.forEach(item => this.carouselList.appendChild(createItem(item)));
    this.carousel = new Flickity(CAROUSEL_SELECTOR, CAROUSEL_CONFIG);

    this.setCarouselCells();
    this.initControls();
    this.onCarouselChange();
    this.carousel.on('change', this.onCarouselChange);
  };

  private initControls() {
    const { CAROUSEL_CONTROL_SELECTOR } = CarouselComponent;
    const controls = Array.from(document.querySelectorAll<HTMLButtonElement>(CAROUSEL_CONTROL_SELECTOR))!;

    controls.forEach(control => {
      const direction = control.dataset.direction! as CarouselDirections;
      this.carouselControls[direction] = control;
      control.addEventListener('click', () => this.carousel[direction]())
    });
  }

  private onCarouselChange = () => {
    const { selectedIndex, slides } = this.carousel;
    const lastSlide = slides.length - 1;
    const prevBtn = this.carouselControls.previous!;
    const nextBtn = this.carouselControls.next!;

    if (selectedIndex === 0) {
      prevBtn.setAttribute('disabled', 'disabled');
    } else {
      prevBtn.removeAttribute('disabled');
    }

    if (selectedIndex === lastSlide) {
      nextBtn.setAttribute('disabled', 'disabled');
    } else {
      nextBtn.removeAttribute('disabled');
    }
  };

  private fetchItems(): Promise<CarouselItem[]> {
    if (process.env.NODE_ENV === 'development') {
      return import('./dummy-data.json');
    } else {
      return new Promise((resolve, reject) => {
        const xhr = new XMLHttpRequest();

        xhr.open('GET', `${process.env.PUBLIC_URL}/carousel-data.json`, true);
        xhr.responseType = 'json';
        xhr.addEventListener('load', () => {
          try {
            resolve(xhr.response);
          } catch (e) {
            reject(e);
          }
        });
        xhr.addEventListener('error', e => reject(e));
        xhr.send();
      })
    }
  }

  private setCarouselCells() {
    const width = window.innerWidth;
    const { CAROUSEL_BREAKPOINTS } = CarouselComponent;
    const breakpoint = CAROUSEL_BREAKPOINTS.find(b => b.breakpoint > width)!;
    this.carousel.option({ groupCells: breakpoint.cells });
    this.carousel.resize();
  }
}
