import './circle-graph.component.scss';

const svg = (id: number) => `
<svg class="circle-graph__svg" xmlns="http://www.w3.org/2000/svg" width="100%" height="100%" viewBox="0 0 200 200">
    <defs>
        <linearGradient id="grad${id}">
            <stop offset="0%" stop-color="#3E75C8"/>
            <stop offset="100%" stop-color="#233B6E"/>
        </linearGradient>
    </defs>
    <circle r="90" cx="100" cy="100" fill="transparent" stroke="#d5e8ed" stroke-width="10" stroke-dasharray="565.48" stroke-dashoffset="0" />
    <circle class="circle-graph__svg-bar js-svg-bar" r="90" cx="100" cy="100"
            fill="transparent"
            stroke="url(#grad${id})"
            stroke-linecap="round"
            stroke-dasharray="565.48"
            stroke-dashoffset="0"
            stroke-width="15"
    />
</svg>
`;


export class CircleGraphComponent {
  private static readonly circleGraphBaseClass = 'circle-graph';
  private static readonly circleGraphSelector = '.js-circle-graph';
  private static readonly circleGraphBarSelector = '.js-svg-bar';

  private readonly items: HTMLElement[];

  private static setCircleValue(item: HTMLElement, value: number = 100) {
    const { circleGraphBarSelector } = CircleGraphComponent;
    const $circle = item.querySelector<SVGCircleElement>(circleGraphBarSelector)!;
    const r = +$circle.getAttribute('r')!;
    const c = Math.PI * (r * 2);
    const pct = ((100 - value) / 100) * c;

    $circle.style.strokeDashoffset = pct.toString();
  }

  private static getValueString(value: number): string {
    const { circleGraphBaseClass } = CircleGraphComponent;

    return `
        <div class="${circleGraphBaseClass}__value">
            <div class="${circleGraphBaseClass}__value-content">
                <span class="${circleGraphBaseClass}__value-prefix">на</span>
                <span class="${circleGraphBaseClass}__value-num">${value}</span>
                <span class="${circleGraphBaseClass}__value-suffix">%</span>
            </div>
        </div>
    `.trim();
  }

  static init() {
    new CircleGraphComponent();
  }

  private constructor() {
    this.items = Array.from(document.querySelectorAll<HTMLElement>(CircleGraphComponent.circleGraphSelector));
    this.items.forEach(elem => this.initItem(elem));
  }

  private initItem(item: HTMLElement): void {
    const { circleGraphBaseClass } = CircleGraphComponent;
    const value = +item.dataset.value!;

    item.classList.add(circleGraphBaseClass);
    item.innerHTML = svg(Date.now() + Math.random() * 100) + CircleGraphComponent.getValueString(value);
    CircleGraphComponent.setCircleValue(item, 0);

    setTimeout(() => CircleGraphComponent.setCircleValue(item, value), 0);
  }
}
