/**
* https://gist.github.com/andjosh/6764939
*/
import { getWindowScrollY } from './window-scroll-y';

export function animatedScrollTo(
  scrollTo: HTMLElement | number,
  duration: number
) {
  const start = getWindowScrollY();
  const end = typeof scrollTo === 'number' ? scrollTo : scrollTo.offsetTop;
  let currentTime = 0;
  const increment = 16;

  const animateScroll = () => {
    currentTime += increment;
    const scrollVal = interpolate(currentTime, start, end, duration);
    window.scrollTo(0, scrollVal);
    if (currentTime < duration) window.requestAnimationFrame(animateScroll);
  };

  animateScroll();
}

// t = current time
// b = start value
// c = change in value
// d = duration
function interpolate(t: number, b: number, c: number, d: number) {
  return (t / d * (c - b)) + b;
}
