import '../components/data-graphs/data-graphs.component';
import '../components/regulations/regulations.component';
import '../components/technologies/technologies.component';
import '../components/footer/footer.component';
import '../components/input/input.component';
import '../components/button/button.component';
import { HeaderComponent } from '../components/header/header.component';
import { CircleGraphComponent } from '../components/circle-graph/circle-graph.component';
import { CarouselComponent } from '../components/carousel/carousel.component';

HeaderComponent.init();
CircleGraphComponent.init();
CarouselComponent.init();
