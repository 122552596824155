import './feedback-modal.component.scss';

export class FeedbackModalComponent {
  private static readonly html: string = require('./feedback-modal.component.html');
  private static readonly errorMsgs = {
    nameRequired: 'Укажите как к вам обращаться',
    contactRequired: 'Укажите как с вами связаться'
  };

  private static readonly MODAL_SELECTOR = '.js-modal';
  private static readonly MODAL_BACKDROP_SELECTOR = '.js-modal-backdrop';
  private static readonly MODAL_CLOSE_SELECTOR = '.js-modal-close';
  private static readonly FORM_SELECTOR = '.js-form';
  private static readonly FORM_ERRORS_SELECTOR = '.js-form-errors';
  private static readonly MODAL_OPENED_CLASS = 'feedback-modal--opened';
  private static readonly MODAL_CLOSING_CLASS = 'feedback-modal--closing';
  private static instance: FeedbackModalComponent;

  private modal!: HTMLDivElement;
  private modalBackdrop!: HTMLDivElement;
  private modalCloseElements!: HTMLElement[];
  private form!: HTMLFormElement;
  private formErrors!: HTMLDivElement;

  static init() {
    if (FeedbackModalComponent.instance) {
      return FeedbackModalComponent.instance = new FeedbackModalComponent();
    }

    return FeedbackModalComponent.instance = new FeedbackModalComponent();
  }

  private constructor() {
    const wrapper = document.createElement('div');
    wrapper.innerHTML = FeedbackModalComponent.html;
    document.body.appendChild(wrapper.firstChild!);
    setTimeout(() => this.initElements(), 0);
  }

  open() {
    this.modal.classList.add(FeedbackModalComponent.MODAL_OPENED_CLASS);
    this.listenEvents();
    this.blockScroll()
  }

  close = () => {
    const { MODAL_CLOSING_CLASS } = FeedbackModalComponent;

    this.unlistenEvents();
    this.unblockScroll();
    this.modal.classList.add(MODAL_CLOSING_CLASS);
    this.modalBackdrop.addEventListener('transitionend', () => this.reset(), { once: true });
  };

  private get userNameInput(): HTMLInputElement {
    // @ts-ignore
    return this.form.elements['userName'];
  }

  private get contactInput(): HTMLInputElement {
    // @ts-ignore
    return this.form.elements['contact'];
  }

  private set errorMessage(value: string) {
    this.formErrors.innerText = value;
  }

  private initElements(): void {
    const {
      MODAL_SELECTOR,
      MODAL_BACKDROP_SELECTOR,
      MODAL_CLOSE_SELECTOR,
      FORM_SELECTOR,
      FORM_ERRORS_SELECTOR
    } = FeedbackModalComponent;
    this.modal = document.querySelector<HTMLDivElement>(MODAL_SELECTOR)!;
    this.modalBackdrop = this.modal.querySelector<HTMLDivElement>(MODAL_BACKDROP_SELECTOR)!;
    this.modalCloseElements = Array.from(this.modal.querySelectorAll<HTMLElement>(MODAL_CLOSE_SELECTOR))!;
    this.form = this.modal.querySelector<HTMLFormElement>(FORM_SELECTOR)!;
    this.formErrors = this.form.querySelector<HTMLDivElement>(FORM_ERRORS_SELECTOR)!;
  }

  private onFormSubmit = (e: Event): void => {
    e.preventDefault();

    this.errorMessage = '';
    const userName = this.userNameInput.value;
    const contact = this.contactInput.value;
    const errorMsg = this.validate(userName, contact);

    if (errorMsg) {
      this.errorMessage = errorMsg;
      return;
    }

  };

  private validate(userName: string, contact: string): string | null {
    const { errorMsgs } = FeedbackModalComponent;

    if (!userName) return errorMsgs.nameRequired;
    else if (!contact) return errorMsgs.contactRequired;
    return null;
  }

  private listenEvents(): void {
    this.form.addEventListener('submit', this.onFormSubmit);
    this.modalCloseElements.forEach(elem => elem.addEventListener('click', this.close));
  }

  private unlistenEvents(): void {
    this.form.removeEventListener('submit', this.onFormSubmit);
    this.modalCloseElements.forEach(elem => elem.removeEventListener('click', this.close));
  }

  private blockScroll() {
    document.body.style.overflow = 'hidden';
  }

  private unblockScroll() {
    document.body.style.overflow = null;
  }

  private reset() {
    const { MODAL_OPENED_CLASS, MODAL_CLOSING_CLASS } = FeedbackModalComponent;

    this.modal.classList.remove(MODAL_OPENED_CLASS, MODAL_CLOSING_CLASS);
    this.errorMessage = '';
    this.userNameInput.value = '';
    this.contactInput.value = '';
  }
}
