import './header.component.scss';
import throttle from 'lodash-es/throttle';
import debounce from 'lodash-es/debounce';

import { animatedScrollTo } from '../utils/scroll-to';
import { getWindowScrollY } from '../utils/window-scroll-y';
import { FeedbackModalComponent } from '../feedback-modal/feedback-modal.component';

interface NavBlock {
  element: HTMLElement;
  offsetTop: number;
  id: string;
}

export class HeaderComponent {
  private static readonly OFFSET = 300;
  private static readonly MOBILE_BREAKPOINT = 768;
  private static readonly HEADER_SELECTOR = '.js-header';
  private static readonly NAV_LINK_SELECTOR = '.js-nav-link';
  private static readonly NEXT_BTN_SELECTOR = '.js-next-btn';
  private static readonly HEADER_BTN_SELECTOR = '.js-header-btn';
  private static readonly HEADER_SHRINK_CLASS = 'header--shrink';
  private static readonly NAV_LINK_ACTIVE_CLASS = 'header__nav-link--active';

  private _headerHeight!: number;
  private header: HTMLElement;
  private headerStretchOffset: number = HeaderComponent.getHeaderStretchOffset();
  private links: HTMLAnchorElement[] = [];
  private navBlocks: NavBlock[] = [];
  private isHeaderAnimating: boolean = false;
  private activeHash: string = '';
  private isMobile: boolean = window.innerWidth <= HeaderComponent.MOBILE_BREAKPOINT;
  private modal: FeedbackModalComponent = FeedbackModalComponent.init();

  get headerHeight(): number {
    return this._headerHeight;
  }

  set headerHeight(value: number) {
    this._headerHeight = value;
    this.header.style.height = `${value}px`;
  }

  private static getHeaderStretchOffset(): number {
    return window.innerWidth <= 800 || window.innerHeight <= 400 ? 60 : 125;
  }

  static init() {
    return new HeaderComponent();
  }

  private constructor() {
    this.header = document.querySelector<HTMLElement>(HeaderComponent.HEADER_SELECTOR)!;

    this.setHeaderHeight();
    this.initNextBtn();
    this.initContanctBtn();
    this.initNavLinks();
    this.initNavBlocks();

    this.onResize();
    window.addEventListener('resize', this.onResize);
  }

  private setHeaderHeight() {
    const { MOBILE_BREAKPOINT } = HeaderComponent;
    this.headerHeight = this.isMobile ?
      640 :
      window.innerHeight >= MOBILE_BREAKPOINT ? window.innerHeight : MOBILE_BREAKPOINT;
  }

  private initNextBtn(): void {
    const nextBtn = this.header.querySelector<HTMLButtonElement>(HeaderComponent.NEXT_BTN_SELECTOR)!;
    nextBtn.addEventListener('click', () => {
      this.scrollToElement('carousel');
    });
  }

  private initNavLinks(): void {
    const { NAV_LINK_SELECTOR } = HeaderComponent;

    this.links = Array.from(this.header.querySelectorAll<HTMLAnchorElement>(NAV_LINK_SELECTOR)!);

    if (window.innerWidth < 768) return;
    this.links.forEach(link => link.addEventListener('click', this.onNavLinkClick));
  }

  private initNavBlocks(): void {
    this.navBlocks = this.links
      .map(link => {
        const id = link.hash.slice(1);
        const elem = document.getElementById(id) as HTMLElement;
        return {
          element: elem,
          offsetTop: elem.offsetTop,
          id
        };
      });
  }

  private onNavLinkClick = (e: MouseEvent): void => {
    e.preventDefault();

    const link = e.target as HTMLAnchorElement;
    this.changeURLState(link.hash);
    this.scrollToElement(link.hash.slice(1));
  };

  private scrollToElement(elemId: string): void {
    const block = this.navBlocks.find(b => b.id === elemId)!;
    animatedScrollTo(block.offsetTop - this.headerStretchOffset, 400);
  }

  private onScroll = throttle((): void => {
    const scrollY = getWindowScrollY();
    this.checkForActiveBlock(scrollY);
    this.checkForHeaderShrink(scrollY);
  }, 150);

  private onResize = debounce(() => {
    this.isMobile = window.innerWidth <= HeaderComponent.MOBILE_BREAKPOINT;

    this.setHeaderHeight();
    this.recalcBlocksOffset();
    this.setActiveLink();
    this.onScroll();
    window.addEventListener('scroll', this.onScroll);
  }, 500);

  private checkForHeaderShrink(scrollY: number): void {
    const { HEADER_SHRINK_CLASS } = HeaderComponent;
    const offset = this.headerStretchOffset + 25;

    if (this.isHeaderAnimating) return;

    if (scrollY > this.headerHeight - offset && !this.header.classList.contains(HEADER_SHRINK_CLASS)) {
      this.header.classList.add(HEADER_SHRINK_CLASS);
    }

    if (scrollY < this.headerHeight - offset && this.header.classList.contains(HEADER_SHRINK_CLASS)) {
      this.header.classList.remove(HEADER_SHRINK_CLASS);
    }
  }

  private checkForActiveBlock(scrollY: number): void {
    const { OFFSET } = HeaderComponent;
    const navBlock = this.navBlocks.filter(b => b.offsetTop - OFFSET <= scrollY).pop()!;
    const hash = `#${navBlock.id}`;

    if (hash !== this.activeHash) this.changeURLState(hash);
  }

  private changeURLState(hash: string): void {
    this.activeHash = hash;
    this.setActiveLink(hash);
  }

  private setActiveLink(hash?: string): void {
    const { NAV_LINK_ACTIVE_CLASS } = HeaderComponent;
    const link = !hash ? this.links[0] : this.links.find(l => l.hash === hash)!;

    this.links.forEach(link => link.classList.remove(NAV_LINK_ACTIVE_CLASS));
    link.classList.add(NAV_LINK_ACTIVE_CLASS);
  }

  private recalcBlocksOffset() {
    this.navBlocks.forEach(block => {
      block.offsetTop = block.element.offsetTop;
    });
  }

  private initContanctBtn() {
    const contactBtn = this.header.querySelector<HTMLButtonElement>(HeaderComponent.HEADER_BTN_SELECTOR)!;

    contactBtn.addEventListener('click', () => this.modal.open());
  }
}
